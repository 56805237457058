
import Product from '../components/Product';

const Products = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
 
      <Product />
   

      
      {/* <div className='h-[4000px]'></div> */}
    </div>
  )
}

export default Products;