
import Companys from '../components/Companys'

const Company = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
 
      <Companys />
   

      
      {/* <div className='h-[4000px]'></div> */}
    </div>
  )
}

export default Company;
