import Contacts from '../components/Contacts';


const Contact = () => {
  return (
    <div className='max-w-[1920px] mx-auto overflow-hidden bg-white'>
 
      <Contacts />
   

      
      {/* <div className='h-[4000px]'></div> */}
    </div>
  );
};

export default Contact;